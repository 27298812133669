import React from "react";
import { Link } from "react-router-dom";

const faqContent = [
  {
    title: "General:",
    faqInner: [
      {
        title: "What types of websites can Eklonet build?",
        descriptions: `We specialize in building custom websites for
        businesses and individuals of all types and sizes,
        including e-commerce sites, landing pages, content
        management systems, etc.`,
      },
      {
        title: "Do you provide an NDA Agreement?",
        descriptions: `
        If it is necessary based on the contents of the project.`,
      },
      {
        title: "How many revisions do I get with my website?",
        descriptions: `
        Each client's website will get three revision rounds included in their website's price. If the client requests more revisions beyond the included rounds, additional fees may apply.`,
      },
      {
        title: "How does Eklonet handle website security?",
        descriptions: `
        We take website security seriously and use a variety
                          of measures to protect your website, including SSL
                          certificates, firewalls, and regular backups.`,
      },
      {
        title:
          "What is responsive design and why is it important for my website?",
        descriptions: `
        Responsive design is a technique used to create a
                          website that adapts to the user's screen size and
                          device. It is important because it ensures that your
                          website looks great on all devices, including mobile
                          devices, which are used more and more to access
                          websites.`,
      },
      {
        title:
          "Does Eklonet provide website hosting and domain registration services?",
        descriptions: `
        Yes, we provide website hosting and domain
                          registration services. We can help you choose the
                          right hosting plan for your needs and register your
                          domain name.`,
      },
    ],
  },
  {
    title: "CMS:",
    faqInner: [
      {
        title: "Can I update my CMS website myself?",
        descriptions: `Yes, one of the main advantages of a CMS website is that it allows you to update your website's content without needing advanced technical knowledge. `,
      },
      {
        title: "What types of websites can be built using a CMS?",
        descriptions: `
        CMS websites can be used to build a wide range of websites, including blogs, e-commerce sites, portfolio sites, business websites, and more.`,
      },
      {
        title: "How do I maintain my CMS website?",
        descriptions: `Maintaining a CMS website involves regular updates to the platform, plugins, and extensions to ensure that they are secure and functioning properly.`,
      },
    ],
  },
  {
    title: "Maintenance:",
    faqInner: [
      {
        title:
          "What kind of support does Eklonet offer after my website is launched?",
        descriptions: `
        We offer ongoing support after your website is
                          launched to ensure that it remains up-to-date and
                          working properly. We provide technical support,
                          software updates, and assistance with any issues that
                          may arise.`,
      },
      {
        title: "Why is website maintenance important?",
        descriptions: `Regular website maintenance is important to ensure that your website remains secure, performs well, and provides a positive user experience.`,
      },
      {
        title: "How much does website maintenance cost?",
        descriptions: `The cost of website maintenance can vary depending on the specific needs of your website, the level of complexity, and the frequency of maintenance required.`,
      },
    ],
  },
  {
    title: "SEO:",
    faqInner: [
      {
        title:
          "How does Eklonet ensure the website is optimized for search engines?",
        descriptions: `We use industry-standard search engine optimization
        (SEO) techniques to optimize your website for search
        engines, including optimizing content, improving site
        structure and navigation, and ensuring fast loading
        times.`,
      },
      {
        title: "How much does SEO cost?",
        descriptions: `The cost of SEO can vary depending on the specific needs of your website, the level of competition in your industry, and the goals of your SEO campaign.`,
      },
      {
        title: "What happens if I don't do SEO?",
        descriptions: `
        If SEO is not performed, your website may not appear in search engine results pages for relevant search terms and phrases, resulting in lower visibility and fewer potential customers.`,
      },
      {
        title: "How long does it take to see results from SEO?",
        descriptions: `The time it takes to see results from SEO can vary depending on various factors, such as the competitiveness of the industry and the level of optimization required. Generally, it can take several months or more to see significant improvements in search engine rankings and website traffic.`,
      },
    ],
  },
  {
    title: "E-Commerce:",
    faqInner: [
      {
        title: "What kind of e-commerce platforms does Eklonet support?",
        descriptions: `We support a range of e-commerce platforms, including
        WooCommerce, Shopify, and Magento.`,
      },
      {
        title: "Why is e-commerce important?",
        descriptions: `E-commerce is important because it allows businesses to reach a wider audience and sell products or services 24/7, without the limitations of physical locations or business hours.`,
      },
      {
        title:
          "Can I add new products and categories to my e-commerce website?",
        descriptions: `
        Yes, e-commerce websites can be easily updated and maintained by the client, using a content management system (CMS).`,
      },
      {
        title: "How do I handle payments for my e-commerce website?",
        descriptions: `Handling payments for an e-commerce website may involve integrating with various payment gateway providers, such as PayPal, Stripe, or Square.`,
      },
    ],
  },
];

const FaqTwo = () => {
  return (
    <section>
      {/* <!--Spacer--> */}

      <div className="container-xxl">
        {faqContent.map((item, i) => (
          <div key={i}>
            <div className="ptf-animated-block" data-aos="fade">
              {/* <!--FAQ--> */}
              <div className="ptf-faq">
                <div className="ptf-faq__category">
                  <h3>{item.title}</h3>
                </div>
                <div className="ptf-faq__content">
                  {item.faqInner.map((val, i) => (
                    <div className="ptf-faq__item" key={i}>
                      <div className="ptf-faq__question">
                        <h6>{val.title}</h6>
                      </div>
                      <div className="ptf-faq__answer">
                        <p>{val.descriptions}</p>
                      </div>
                    </div>
                  ))}
                  {/* End .ptf-faq__item */}
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5.625rem" }}
            ></div>
          </div>
        ))}

        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "4.375rem" }}></div>
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade">
          <p className="fz-24">
            Didn’t find your question?{" "}
            <Link
              className="has-accent-4 text-decoration-underline"
              to="/contact"
            >
              Contact us{""} 
            </Link>
            &nbsp;now, we are always willing to help you.
          </p>
        </div>
      </div>
      {/* <!--Spacer--> */}
      <div
        className="ptf-spacer"
        style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
      ></div>
    </section>
  );
};

export default FaqTwo;

import React from "react";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import Social from "../../social/Social";
import SliderMenu from "./SliderMenu";
import ContactForm from "../../ContactForm";

const MobileMenu = ({ open, handleMenuOpen }) => {
  return (
    <>
      <div className="ptf-offcanvas-menu__header">
        <div className="ptf-offcanvas-menu__header-title"></div>
        <div className="ptf-offcanvas-menu__header-action">
          <div
            className="ptf-offcanvas-menu-icon js-offcanvas-menu-toggle bar right"
            onClick={handleMenuOpen}
          >
            <i className="lnir lnir-close"></i>
          </div>
        </div>
      </div>
      <div className="ptf-offcanvas-menu__slider">
        <SliderMenu />
      </div>
      <div className="ptf-offcanvas-menu__body">
        <div className="container-xxl">
          <div className="text-center">
            <img src="/assets/img/freequote.png" alt="Free Quote" />
          </div>
          <div className="ptf-spacer" style={{ "--ptf-xxl": "1.5rem" }}></div>
          <ContactForm size="small" showLabel={false} />
        </div>
      </div>
      <div className="ptf-offcanvas-menu__footer">
        <p className="ptf-offcanvas-menu__copyright">
          &copy;{new Date().getFullYear()} Eklonet |{" "}
          <Link to={"/CopyRight"}>All Rights Reserved</Link> <br />
          Development by <Link to={"/"}>Eklonet</Link>
        </p>
        <Social />
      </div>
    </>
  );
};

export default MobileMenu;

import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../../components/header/Header";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";

const CopyRightPage = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--works-carousel">
      <Helmet>
        <title>Eklonet - Copyright</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <Header />
        {/* End  HeaderHomeDefault */}

        <div className="ptf-main">
          <div className="ptf-page ptf-page--portfolio-carousel">
            {/*=============================================
                End Portfolio Section
              ============================================== */}
            <section>
              {/* spacer */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-12 col-md-9">
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading d-inline-flex">Copyright</h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 text-md-end">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-md": "1.875rem" }}
                    ></div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "3.25rem", "--ptf-md": "5rem" }}
                ></div>
                <p>
                  <br />
                  <span style={{ fontSize: "26px" }}>
                  This Copyright Legal Document (the "Agreement") governs the use of all 
                  copyrighted material ("Material") created by Eklonet ("Company"), a 
                  Louisiana-based online web development company.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    1. All of the content and material on the website www.eklonet.com, 
                    including but not limited to text, graphics, logos, icons, images, 
                    audio clips, digital downloads, data compilations, and software, 
                    is the exclusive property of Eklonet and is protected by 
                    United States and international copyright laws.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    2. Eklonet retains all rights, title, and interest in and to the 
                    content and material on the website, including all intellectual property rights.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    3. Any unauthorized reproduction, modification, distribution, transmission, 
                    republication, display, or performance of any content or material on the 
                    website is strictly prohibited and may result in legal action.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    4. Eklonet respects the intellectual property rights of others and 
                    expects its users to do the same. If you believe that any content or 
                    material on the website infringes your copyright, please 
                    contact us at&nbsp;<a
                      href="mailto:contact@eklonet.com"
                      style={{ color: "#28abe3" }}
                    >contact@eklonet.com</a>.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    5. Eklonet creates new websites for clients and grants them all 
                    rights to the content and material on their websites. Eklonet does 
                    not claim any ownership or copyright to the content and material 
                    on its clients' websites.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    6. This copyright legal document constitutes the entire agreement 
                    between Eklonet and its users with respect to the use of the 
                    website and supersedes all prior or contemporaneous communications 
                    and proposals, whether oral or written, between Eklonet and its users.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                  By using Eklonet's website or engaging Eklonet for web development 
                  services, you agree to be bound by this Agreement.
                  </span>
                </p>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "9rem" }}
              ></div>
            </section>
          </div>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default CopyRightPage;

import React from "react";
import { Routes, Route } from "react-router-dom";

import AboutUs from "../views/inner-pages/AboutUs";
import Articles from "../views/inner-pages/articles/Articles";
import ArticleDetail from "../views/inner-pages/articles/ArticleDetail";
import Careers from "../views/inner-pages/careers/Careers";
import CareerApply from "../views/inner-pages/careers/CareerApply";
import CareerApplySuccess from "../views/inner-pages/careers/CareerApplySuccess";
import CareerDetail from "../views/inner-pages/careers/CareerDetail";
import Contact from "../views/inner-pages/contact/Contact";
import ContactSuccess from "../views/inner-pages/contact/ContactSuccess";
import Cookie from "../views/inner-pages/cookie/Cookie";
import CopyRight from "../views/inner-pages/copyright/CopyRight";
import Faq from "../views/inner-pages/Faq";
import Home from "../views/home/Home";
import NotFound from "../views/NotFound";
import Privacy from "../views/inner-pages/privacy/Privacy";
import PortfolioProjects from "../views/inner-pages/portfolio/PortfolioProjects";
import Referrals from "../views/inner-pages/referrals/Referrals";
import ScrollTopBehavior from "../components/ScrollTopBehavior";
import Services from "../views/inner-pages/services/Services";
import ServiceDetails from "../views/inner-pages/services/ServiceDetails";
import Terms from "../views/inner-pages/terms/Terms";

function AllRoutes() {
  return (
    <>
      <ScrollTopBehavior />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<AboutUs />} />
        {/* <Route path="/articles" element={<Articles />} />
        <Route path="/articles/:id" element={<ArticleDetail />} /> */}
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" element={<CareerDetail />} />
        <Route path="/careers/:id/apply" element={<CareerApply />} />
        <Route
          path="/careers/:id/apply/success"
          element={<CareerApplySuccess />}
        />
        <Route path="/contact" element={<Contact />} />
        <Route path="/contact/success" element={<ContactSuccess />} />
        <Route path="/copyright" element={<CopyRight />} />
        <Route path="/cookie" element={<Cookie />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/portfolio" element={<PortfolioProjects />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/referrals" element={<Referrals />} />
        <Route path="/services" element={<Services />} />
        <Route path="/service-details" element={<ServiceDetails />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default AllRoutes;

import React from "react";

import { popularProjects } from "../../../utils/data";

const PopularProjects = () => {
  return (
    <div className="row">
      {popularProjects.map((item, i) => (
        <div className="col-md-6 col-xl-4" key={i}>
          <a href={item.link} target="_blank" rel="noreferrer">
            <article className="ptf-work ptf-work--style-3">
              <div className="ptf-work__media">
                {/* add 'works-showcase' back after the / whenever the shawcase page gets built */}
                <img src={item.img} alt="" loading="lazy" />
              </div>
              <div className="ptf-work__meta">
                <div className="ptf-work__category">{item.subTitle}</div>
                <h4 className="ptf-work__title">
                  {item.title}
                  {/* add 'works-showcase' back after the / whenever the shawcase page gets built */}
                </h4>
              </div>
            </article>
          </a>
        </div>
      ))}
    </div>
  );
};

export default PopularProjects;

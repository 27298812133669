import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import emailjs from "@emailjs/browser";

// import { getJob } from "../../../utils/contentful/jobs";
import { uploadFile } from "../../../utils/cloudinary";

import Header from "../../../components/header/Header";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import JobApplyForm from "../../../components/JobApplyForm";
import Address from "../../../components/Address";

import { careers } from "../../../utils/data";

const CareerApply = () => {
  const params = useParams();
  const navigate = useNavigate();
  const jobId = params?.id;

  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    (async () => {
      if (jobId) {
        if (mounted) {
          setLoading(true);
          // const _job = await getJob(jobId);
          const _job = careers.find((career) => career.id === jobId);
          setLoading(false);
          setJob(_job);
        }
      }
    })();

    return () => {
      mounted = false;
    };
  }, [jobId]);

  const handleSubmit = async (data) => {
    const file = data.resume[0];
    const uploadResponse = await uploadFile(file);

    if (uploadResponse.status === "success") {
      const templateData = {
        jobId: jobId,
        jobTitle: job.title,
        jobLink:
          job?.link || `${process.env.REACT_APP_SITE_DOMAIN}/careers/${jobId}`,
        name: data.name,
        email: data.email,
        location: data.location,
        coverLetter: data.coverLetter,
        resume: {
          name: file.name,
          type: file.type,
          url: uploadResponse.url,
        },
      };
      const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
      const templateID = process.env.REACT_APP_JOB_APPLY_EMAIL_TEMPLATE_ID;
      const userID = process.env.REACT_APP_EMAIL_USER_ID;

      try {
        emailjs
          .send(serviceID, templateID, templateData, userID, {
            attachment: data.resume.data,
          })
          .then(
            (response) => {
              console.log("SUCCESS!", response.status, response.text);
              navigate(`/careers/${jobId}/apply/success?flag=true`);
            },
            (error) => {
              console.log("FAILED...", error);
              navigate(`/careers/${jobId}/apply/success?flag=false`);
            }
          );
      } catch (error) {
        console.error("Email sending failed: ", error);
      }
    }
  };

  return (
    <div>
      <Helmet>
        <title>Eklonet - Career Apply</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row">
                <div className="col-xl-10">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <h1>
                      {loading ? (
                        <Skeleton count={1} width="50%" />
                      ) : (
                        <>
                          Apply For{" "}
                          {job?.link ? (
                            <a
                              href={job?.link}
                              className="has-accent-1"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {job?.title}
                            </a>
                          ) : (
                            <Link
                              className="has-accent-1"
                              to={`/careers/${job?.id}`}
                            >
                              {job?.title}
                            </Link>
                          )}
                        </>
                      )}
                    </h1>

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3rem", "--ptf-md": "2.5rem" }}
                    ></div>
                  </div>
                </div>
              </div>
              {/* End .row */}
            </div>
            {/* Contact top portion */}

            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End section contact header */}
          <section>
            <div className="container-xxl">
              <div className="row">
                <div className="col-lg-8">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="300"
                  >
                    <div className="container-xxl">
                      <h5 className="fz-14 text-uppercase has-3-color fw-normal">
                        Submit Your Application
                      </h5>
                      {/* <!--Spacer--> */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "3.125rem" }}
                      ></div>
                      <JobApplyForm size="large" submit={handleSubmit} />
                      {/* End ContactForm */}
                      <div
                        className="ptf-spacer"
                        style={{ "--ptf-xxl": "3.125rem" }}
                      ></div>
                    </div>
                  </div>
                </div>
                {/* End .col */}

                <div className="col-lg-4">
                  <div className="container-xxl">
                    <Address />
                  </div>
                </div>
                {/* End .col */}
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CareerApply;

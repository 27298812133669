import React from "react";
import { Link } from "react-router-dom";

const footerItems = [
  { itemName: "Home", link: "/" },
  { itemName: "Services", link: "/services" },
  { itemName: "Portfolio", link: "/portfolio" },
  // { itemName: "Articles", link: "/articles" },
  { itemName: "Contact", link: "/contact" },
  { itemName: "About Us", link: "/about-us" },
  { itemName: "FAQs", link: "/faq" },
  { itemName: "Careers", link: "/careers" },
  { itemName: "Terms & Conditions", link: "/terms" },
  { itemName: "Privacy Policy", link: "/privacy" },
  { itemName: "Referrals", link: "/referrals" },
];

const FooterList = () => {
  return (
    <ul>
      {footerItems.map((item, i) => (
        <li key={i}>
          <Link to={item.link}>{item.itemName}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterList;

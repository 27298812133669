import { Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

const CookiePopup = () => {
  return (
    <div className="ptf-cookie-popup">
      <CookieConsent
        cookieName="cookie-eklonet"
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        enableDeclineButton
        style={{
          background: "var(--ptf-color-16)",
          border: "1px solid var(--ptf-color-black)",
          zIndex: "990",
          opacity: "0.8",
        }}
        buttonStyle={{
          background: "transparent",
          color: "var(--ptf-accent-1)",
          fontSize: "1rem",
          fontWeight: 600,
        }}
        declineButtonStyle={{
          background: "transparent",
          color: "var(--ptf-color-4)",
          fontSize: "1rem",
        }}
        expires={150}
      >
        We use cookies to give you the best possible experience on{" "}
        <strong>Eklonet</strong>, and to provide web analytics that help us
        improve our site. To find out more, check out our cookies policy.{" "}
        <Link to="/cookie" className="has-accent-1 fw-bold">
          Learn more
        </Link>
      </CookieConsent>
    </div>
  );
};

export default CookiePopup;

import axios from "axios";

const uploadFile = async (file) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append(
    "upload_preset",
    process.env.REACT_APP_CLOUDINARY_PRESET_NAME
  );
  formData.append(
    "public_id",
    `${file.name.split(".").slice(0, -1).join(".")}_${Date.now()}`
  );

  try {
    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/raw/upload`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (response.status === 200)
      return { status: "success", url: response.data.secure_url };
    return { status: "failed" };
  } catch (error) {
    console.error("File upload failed: ", error);
  }
};

export { uploadFile };

import React, { useEffect, useState } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "../../../components/header/Header";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";

const CareerApplySuccess = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [success, setSuccess] = useState(false);
  const jobId = params?.id;

  useEffect(() => {
    if (searchParams.get("flag")) {
      const flag = /true/i.test(searchParams.get("flag"));

      setSuccess(flag);
    } else navigate(`/careers/${jobId}/apply`);
  }, [jobId, searchParams, navigate]);

  return (
    <div>
      <Helmet>
        <title>Eklonet - Career Apply</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            <div className="container-xxl text-center">
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <h2>
                {success
                  ? "Your application was successfully submitted!"
                  : "Something went wrong. Please try again!"}
              </h2>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.125rem" }}
              ></div>
              <Link
                to={success ? "/careers" : `/careers/${jobId}/apply`}
                className="has-accent-1 ptf-link-with-arrow fz-36"
              >
                <i className="lnir lnir-arrow-left"></i>
                Go Back
              </Link>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </div>
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CareerApplySuccess;

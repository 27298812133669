import React from "react";

const brandContent = [
  {
    delayAnimation: "200",
    serviceList: [
      {
        name: "Zapnito",
        image: "/assets/img/root/partners/Zapnito.png",
        link: "https://zapnito.com/",
      },
      {
        name: "Honeycomb",
        image: "/assets/img/root/partners/Honeycomb.png",
        link: "https://www.honeycomb.io/",
      },
      {
        name: "Supabase",
        image: "/assets/img/root/partners/Supabase.png",
        link: "https://supabase.com/",
      },
      {
        name: "Gong",
        image: "/assets/img/root/partners/Gong.png",
        link: "https://www.gong.io/",
      },
    ],
  },
  {
    delayAnimation: "300",
    serviceList: [
      {
        name: "Superhuman",
        image: "/assets/img/root/partners/Superhuman.png",
        link: "https://superhuman.com/",
      },
      {
        name: "Hasura",
        image: "/assets/img/root/partners/Hasura.png",
        link: "https://hasura.io/",
      },
      {
        name: "Airtable",
        image: "/assets/img/root/partners/Airtable.png",
        link: "https://www.airtable.com/",
      },
    ],
  },
];

const BrandFive = () => {
  return (
    <>
      {brandContent.map((item, i) => (
        <div className="col-md-6 col-lg-6" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={item.delayAnimation}
          >
            {/* <!--Services List--> */}
            <ul
              className="ptf-services-list ptf-services-list--style-4"
              style={{
                "--ptf-font-family": "var(--ptf-primary-font)",
              }}
            >
              {item.serviceList.map((val, i) => (
                <li key={i} className="ptf-services-list-item">
                  <a
                    href={val.link}
                    className="ptf-services-list-item-link"
                    target="blank"
                    // style={{
                    // "--ptf-hover-color": val.fill,
                    // }}
                  >
                    {/* <i className={`lnir ${val.image}`}></i> */}
                    <img
                      className="ptf-services-list-item-image"
                      src={val.image}
                      alt={val.name}
                      loading="lazy"
                    />
                    <span className="ptf-services-list-item-name">
                      {val.name}
                    </span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default BrandFive;

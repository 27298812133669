import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";

import DropdownMenu from "./menu/DropdownMenu";
import MobileMenu from "./menu/MobileMenu";

const Header = ({ type = "light" }) => {
  const mobileMenuRef = useRef(null);
  const [openHamburgerMenu, setHamburgerMenuOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);

  const handleHamburgerMenuOpen = () =>
    setHamburgerMenuOpen(!openHamburgerMenu);

  const changeBackground = () => {
    const navbarElement = document.getElementsByClassName("ptf-navbar")[0];

    if (window.scrollY >= navbarElement.clientHeight) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  const handleClickOutside = (event) => {
    if (mobileMenuRef.current && !mobileMenuRef.current.contains(event.target))
      setHamburgerMenuOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", changeBackground);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header
        className={`ptf-header ${
          type === "dark"
            ? "ptf-header-dark"
            : type === "light"
            ? "ptf-header-light"
            : ""
        } ptf-header--style-5 ptf-header--opaque`}
      >
        <div
          className={`ptf-navbar ptf-navbar--main ptf-navbar--sticky ptf-navbar--fixed ${
            navbar ? "ptf-navbar--small" : ""
          }`}
        >
          <div className="container-xxl">
            <div className="ptf-navbar-inner">
              {/* <!--Logo--> */}
              <Link className="ptf-navbar-logo" to="/">
                {type === "dark" ? (
                  <img
                    className="white"
                    src="/assets/img/root/eklonet-logo-dark.png"
                    alt="brand"
                    loading="lazy"
                  />
                ) : (
                  <img
                    className="white"
                    src="/assets/img/root/eklonet-logo-light.png"
                    alt="brand"
                    loading="lazy"
                  />
                )}
              </Link>
              {/* <!--Navigation--> */}
              <nav className="ptf-nav ptf-nav--default">
                <DropdownMenu />
              </nav>

              {/* <!--Offcanvas Menu Toggle--> */}
              {/* <div
                className="ptf-offcanvas-menu-icon js-offcanvas-menu-toggle bar right"
                onClick={handleHamburgerMenuOpen}
              > */}
              <div
                className="js-offcanvas-menu-toggle bar right"
                onClick={handleHamburgerMenuOpen}
              >
                <i className="lnir lnir-menu-alt-5"></i>
              </div>
              {/* Toggle bar icon */}
            </div>
          </div>
        </div>
      </header>
      {/* End header */}

      <div
        ref={mobileMenuRef}
        className={`ptf-offcanvas-menu ${openHamburgerMenu ? "is-open" : ""}`}
      >
        <MobileMenu
          open={openHamburgerMenu}
          handleMenuOpen={handleHamburgerMenuOpen}
        />
      </div>
      {/* End sidebar menu */}
    </>
  );
};

export default Header;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import { services } from "../../utils/data";

const PortfolioGrid = ({ projects }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tabs, setTabs] = useState([]);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    const _tabs = [{ name: "All", value: "" }];

    services.map((service) =>
      _tabs.push({
        name: service.title,
        value: service.slug,
      })
    );

    setTabs(_tabs);
  }, []);

  useEffect(() => {
    const serviceFromUrl = searchParams.get("service") || "";
    const index = tabs.findIndex((tab) => tab.value === serviceFromUrl);

    setSelectedTabIndex(index);
  }, [searchParams, tabs]);

  const getProjectsByService = (service) => {
    return projects.filter(
      (project) =>
        service === "" || project.tags.map((tag) => tag.value).includes(service)
    );
  };

  const handleTabSelected = (selected) => {
    console.log(tabs[selected].value);
    setSelectedTabIndex(selected);
  };

  return (
    <>
      <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabSelected}>
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <TabList className="ptf-filters ptf-filters--style-1">
            {tabs.map((tab, i) => (
              <Tab key={tab.value} className="filter-item" value={tab.value}>
                {tab.name}
              </Tab>
            ))}
          </TabList>
        </div>

        {/* <!--Spacer--> */}
        <div
          className="ptf-spacer"
          style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
        ></div>
        {tabs.map((tab) => (
          <TabPanel key={tab.value}>
            {/* {/* <!--Animated Block--> */}

            <div
              className="ptf-animated-block"
              data-aos="fade"
              data-aos-delay="0"
            >
              <div
                className="ptf-isotope-grid row"
                style={{
                  "--bs-gutter-x": "4.75rem",
                  "--bs-gutter-y": "4.75rem",
                }}
              >
                {getProjectsByService(tab.value).map((project, i) => (
                  <div className="col-lg-4" key={i}>
                    <article className="ptf-work ptf-work--style-1">
                      <div className="ptf-work__media">
                        <a
                          href={project.link}
                          className="ptf-work__link"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {""}
                        </a>
                        <img src={project.img} alt="preview" loading="lazy" />
                      </div>
                      <div className="ptf-work__meta">
                        {/* <div className="ptf-work__category">{project.meta}</div> */}
                        <h4 className="ptf-work__title">
                          <a
                            href={project.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {project.title}
                          </a>
                        </h4>
                        <p className="fz-18 has-3-color">{project.subTitle}</p>
                      </div>
                    </article>
                  </div>
                ))}
                {/* {loading && (
                  <div className="container-xxl text-center">
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3.125rem" }}
                    ></div>
                    <h2 className="has-1-color">Loading...</h2>
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "3.125rem" }}
                    ></div>
                  </div>
                )} */}
                {/* End .col */}
              </div>
            </div>

            {/* End portfolio */}
          </TabPanel>
        ))}
      </Tabs>
    </>
  );
};

export default PortfolioGrid;

import React from "react";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import emailjs from "@emailjs/browser";

const ContactForm = ({ size = "large", showLabel = true }) => {
  const navigate = useNavigate();
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    projectGoal: Yup.string().required("Please, write your project goal."),
    timeline: Yup.string().required("Please write if you have timeline."),
    email: Yup.string()
      .required("Email is required")
      .email("Entered value does not match email format"),
    budget: Yup.string().required("Please select your budget"),
    // acceptTerms: Yup.bool().oneOf(
    //   [true],
    //   "Accept Terms and Conditions is required"
    // ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, reset, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (data) => {
    // display form data on success
    console.log("Message submitted: " + JSON.stringify(data));

    const emailData = {
      name: data.name,
      email: data.email,
      projectGoal: data.projectGoal,
      timeline: data.timeline,
      budget: data.budget,
    };
    const serviceID = process.env.REACT_APP_EMAIL_SERVICE_ID;
    const templateID = process.env.REACT_APP_CONTACT_EMAIL_TEMPLATE_ID;
    const userID = process.env.REACT_APP_EMAIL_USER_ID;

    emailjs.send(serviceID, templateID, emailData, userID).then(
      (response) => {
        console.log("SUCCESS!", response.status, response.text);
        reset();
        navigate("/contact/success?flag=true");
      },
      (error) => {
        console.log("FAILED...", error);
        navigate("/contact/success?flag=false");
        reset();
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`contact-form ptf-form ${
        size === "large"
          ? "ptf-form-large"
          : size === "medium"
          ? "ptf-form-medium"
          : size === "small"
          ? "ptf-form-small"
          : ""
      }`}
    >
      {/* The following two input fields are required for Netlify form handling */}
      <input type="hidden" name="form-name" value="contact" />
      {/* The following field is for spam protection and must be hidden */}
      <input type="hidden" name="bot-field" />

      <div className="ptf-form-group">
        {showLabel && <label data-number="01">What’s your name?</label>}
        <input
          type="text"
          name="name"
          placeholder={!showLabel ? "Full Name" : ""}
          {...register("name")}
          className={`${errors.name ? "is-invalid" : ""}`}
        />
        {errors.name && (
          <div className="invalid-feedback">{errors.name?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        {showLabel && (
          <label data-number="02">What’s your email address?</label>
        )}
        <input
          name="email"
          type="text"
          placeholder={!showLabel ? "Email Address" : ""}
          {...register("email")}
          className={` ${errors.email ? "is-invalid" : ""}`}
        />
        {errors.email && (
          <div className="invalid-feedback">{errors.email?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        {showLabel && (
          <label data-number="03">Tell us about your project goals.</label>
        )}
        <textarea
          type="text"
          name="projectGoal"
          placeholder={!showLabel ? "Project Goals" : ""}
          {...register("projectGoal")}
          className={`${errors.projectGoal ? "is-invalid" : ""}`}
        />
        {errors.projectGoal && (
          <div className="invalid-feedback">{errors.projectGoal?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        {showLabel && (
          <label data-number="04">Do you have a timeline in mind?</label>
        )}
        <input
          type="text"
          name="timeline"
          placeholder={!showLabel ? "Timeline" : ""}
          {...register("timeline")}
          className={`${errors.timeline ? "is-invalid" : ""}`}
        />
        {errors.timeline && (
          <div className="invalid-feedback">{errors.timeline?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        {showLabel && (
          <label data-number="05">
            What have you budgeted for this project?
          </label>
        )}
        <select
          name="budget"
          {...register("budget")}
          className={`${errors.budget ? "is-invalid" : ""}`}
        >
          {!showLabel && <option disabled>Budget</option>}
          <option value="99-499">$99-$499</option>
          <option value="500-999">$500-$999</option>
          <option value="1000-4999">$1,000-$4,999</option>
          <option value="5,000-9,999">$5,000-$9,999</option>
          <option value="10,000 and over">$10,000 and over</option>
        </select>
        {errors.budget && (
          <div className="invalid-feedback">{errors.budget?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      {/* <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div> */}

      {/* <div className="ptf-form-group agreement-checkbox ">
        <input
          name="acceptTerms"
          type="checkbox"
          id="acceptTerms"
          {...register("acceptTerms")}
          className={` ${errors.acceptTerms ? "is-invalid" : ""}`}
        />

        <label className="ptf-checkbox" htmlFor="acceptTerms">
          <span className="ptf-checkbox__checkmark"></span>By clicking this
          checkbox, I am accepting Eklonet's Terms and Conditions!
        </label>
        {errors.acceptTerms && (
          <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
        )}
      </div> */}
      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "2.125rem" }}></div>
      <div className="text-center">
        <button
          type="submit"
          className={`ptf-submit-button ${
            size === "large"
              ? "ptf-submit-button-large"
              : size === "small"
              ? "ptf-submit-button-small"
              : ""
          }`}
        >
          Submit
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 17 17"
          >
            <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
          </svg>
        </button>
      </div>
      {/* End .ptf-submit-button */}
    </form>
  );
};

export default ContactForm;

import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

const JobApplyForm = ({ size = "large", showLabel = true, submit }) => {
  // for validation
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please write your full name"),
    email: Yup.string()
      .required("Please write your email address")
      .email("Entered value does not match email format"),
    location: Yup.string().required("Please write your location"),
    coverLetter: Yup.string().required("Please write your cover letter"),
    resume: Yup.mixed()
      .test("required", "Please upload your resume", (file) => {
        return file[0] && file[0].size;
      })
      .test("fileSize", "The file is too large", (file) => {
        //if u want to allow only certain file sizes
        return file[0] && file[0].size <= 2000000;
      }),
    // acceptTerms: Yup.bool().oneOf(
    //   [true],
    //   "Accept Terms and Conditions is required"
    // ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, reset, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (data) => {
    submit(data);
    reset();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`job-apply-form ptf-form ${
        size === "large"
          ? "ptf-form-large"
          : size === "medium"
          ? "ptf-form-medium"
          : size === "small"
          ? "ptf-form-small"
          : ""
      }`}
    >
      {/* The following two input fields are required for Netlify form handling */}
      <input type="hidden" name="form-name" value="job_apply_form" />
      {/* The following field is for spam protection and must be hidden */}
      <input type="hidden" name="bot-field" />

      <div className="ptf-form-group">
        {showLabel && <label data-number="01">Full Name</label>}
        <input
          type="text"
          name="name"
          placeholder={!showLabel ? "Full Name" : ""}
          {...register("name")}
          className={`${errors.name ? "is-invalid" : ""}`}
        />
        {errors.name && (
          <div className="invalid-feedback">{errors.name?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        {showLabel && <label data-number="02">Email Address</label>}
        <input
          name="email"
          type="text"
          placeholder={!showLabel ? "Email Address" : ""}
          {...register("email")}
          className={` ${errors.email ? "is-invalid" : ""}`}
        />
        {errors.email && (
          <div className="invalid-feedback">{errors.email?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        {showLabel && <label data-number="03">Cover Letter</label>}
        <textarea
          type="text"
          name="coverLetter"
          placeholder={!showLabel ? "Project Goals" : ""}
          {...register("coverLetter")}
          className={`${errors.coverLetter ? "is-invalid" : ""}`}
        />
        {errors.coverLetter && (
          <div className="invalid-feedback">{errors.coverLetter?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        {showLabel && <label data-number="04">Location</label>}
        <input
          type="text"
          name="location"
          placeholder={!showLabel ? "Timeline" : ""}
          {...register("location")}
          className={`${errors.location ? "is-invalid" : ""}`}
        />
        {errors.location && (
          <div className="invalid-feedback">{errors.location?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      <div className="ptf-form-group">
        {showLabel && <label data-number="05">Resume</label>}
        <input
          type="file"
          name="resume"
          {...register("resume")}
          className={`${errors.resume ? "is-invalid" : ""}`}
        />
        {errors.resume && (
          <div className="invalid-feedback">{errors.resume?.message}</div>
        )}
      </div>
      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      {/* <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div> */}

      {/* <div className="ptf-form-group agreement-checkbox ">
        <input
          name="acceptTerms"
          type="checkbox"
          id="acceptTerms"
          {...register("acceptTerms")}
          className={` ${errors.acceptTerms ? "is-invalid" : ""}`}
        />

        <label className="ptf-checkbox" htmlFor="acceptTerms">
          <span className="ptf-checkbox__checkmark"></span>By clicking this
          checkbox, I am accepting Eklonet's Terms and Conditions!
        </label>
        {errors.acceptTerms && (
          <div className="invalid-feedback">{errors.acceptTerms?.message}</div>
        )}
      </div> */}
      {/* End .ptf-form-group */}

      {/* <!--Spacer--> */}
      <div className="ptf-spacer" style={{ "--ptf-xxl": "2.125rem" }}></div>
      <div className="text-center">
        <button
          type="submit"
          className={`ptf-submit-button ${
            size === "large"
              ? "ptf-submit-button-large"
              : size === "small"
              ? "ptf-submit-button-small"
              : ""
          }`}
        >
          Submit
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 17 17"
          >
            <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
          </svg>
        </button>
      </div>
      {/* End .ptf-submit-button */}
    </form>
  );
};

export default JobApplyForm;

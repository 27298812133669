import React from "react";

const SocialShare = ({ url, title, description }) => {
  const domain = process.env.REACT_APP_SITE_DOMAIN;
  const _url = domain + url;
  const handleShareFacebook = async () => {
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      _url
    )}`;
    window.open(shareUrl, "facebook-share-dialog", "width=626,height=436");
  };

  const handleShareTwitter = async () => {
    const shareUrl = `https://twitter.com/share?url=${encodeURIComponent(
      _url
    )}&text=${encodeURIComponent(title)}`;
    window.open(shareUrl, "twitter-share-dialog", "width=626,height=436");
  };

  const handleShareLinkedIn = async () => {
    const shareUrl = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(
      _url
    )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
      description
    )}&source=Eklonet`;
    window.open(shareUrl, "linkedin-share-dialog", "width=626,height=436");
  };

  const handleShareReddit = async () => {
    const redditUrl = `https://www.reddit.com/submit?url=${encodeURIComponent(
      _url
    )}&title=${encodeURIComponent(title)}`;
    window.open(
      redditUrl,
      "Reddit Share",
      "width=550,height=420,left=200,top=200"
    );
  };

  return (
    <>
      {/* <!--Social Icon--> */}
      <a
        className="ptf-social-icon ptf-social-icon--style-3 facebook"
        href="#"
        target="_blank"
        onClick={handleShareFacebook}
      >
        <i className="socicon-facebook"></i>
      </a>
      {/* <!--Social Icon--> */}
      <a
        className="ptf-social-icon ptf-social-icon--style-3 twitter"
        href="#"
        target="_blank"
        onClick={handleShareTwitter}
      >
        <i className="socicon-twitter"></i>
      </a>
      {/* <!--Social Icon--> */}
      <a
        className="ptf-social-icon ptf-social-icon--style-3 linkedin"
        href="#"
        target="_blank"
        onClick={handleShareLinkedIn}
      >
        <i className="socicon-linkedin"></i>
      </a>
      {/* <!--Social Icon--> */}
      <a
        className="ptf-social-icon ptf-social-icon--style-3 reddit"
        href="#"
        target="_blank"
        onClick={handleShareReddit}
      >
        <i className="socicon-reddit"></i>
      </a>
    </>
  );
};

export default SocialShare;

import React from "react";
import Slider from "react-slick";

const testimonialContent = [
  {
    descriptions: ` "The web development team's creativity and expertise have exceeded our expectations, resulting in a website that truly reflects our brand and has helped us stand out in our industry."`,
    name: "David Johnson",
    designation: "Founder of Blue Ocean Enterprises",
  },
  {
    descriptions: ` "Their attention to detail, excellent communication, and commitment to delivering high-quality websites has made them a valuable partner for our company."`,
    name: "Sarah. H",
    designation: "Marketing Director at Stellar Solutions",
  },
  {
    descriptions: ` "Thanks to their exceptional technical skills and customer service, our website now runs smoothly and efficiently, helping us to provide a better user experience for our customers."`,
    name: "Emily Chen",
    designation: "IT Manager at Peak Performance Inc",
  },
  {
    descriptions: ` "Eklonet's developers are truly dedicated to their craft and provided us with a website that not only looks great, but also drove up sales and has helped us achieve our businesses goals."`,
    name: "Michael. S",
    designation: "Velocity Zipline Adventures",
  }
];

const Testimonial = () => {
  const settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <Slider {...settings} className="arrow-none">
        {testimonialContent.map((val, i) => (
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay="100"
            key={i}
          >
            <div
              className="ptf-testimonial ptf-testimonial--style-2"
              style={{
                "--ptf-text-align": "left",
                "--ptf-content-color": "var(--ptf-color-white)",
                "--ptf-author-color": "var(--ptf-color-white)",
                "--ptf-info-color": "var(--ptf-color-white)",
              }}
            >
              <div className="ptf-testimonial__content">
                <p>{val.descriptions}</p>
              </div>
              <div className="ptf-testimonial__meta">
                <h6 className="ptf-testimonial__author">{val.name}</h6>
                <div className="ptf-testimonial__info">{val.designation}</div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Testimonial;

import React, { useEffect } from "react";
import AOS from "aos";
import { Helmet } from "react-helmet";
import AnimatedCursor from "react-animated-cursor";
import { NotificationContainer } from "react-notifications";

import AllRoutes from "./router/AllRoutes";
import ChatBotWidget from "./components/ChatBotWidget";
import CookiePopup from "./components/CookiePopup";
import ScrollToTop from "./components/ScrollToTop";

import "react-chat-widget/lib/styles.css"; // Import the styles
import "react-notifications/lib/notifications.css";
import "react-loading-skeleton/dist/skeleton.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "aos/dist/aos.css";
// import { jarallax } from "jarallax";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Eklonet</title>
        <meta name="description" content="Portfolio & Agency React Template" />
        <meta
          name="keywords"
          content="agency, bootstrap 5, business, clean, corporate, creative, fullpage, minimal, modern, multipurpose, parallax, personal, photography, portfolio, showcase"
        />
      </Helmet>
      {/* End React Helmet for SEO */}

      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="40, 171, 227"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />
      {/* End Animated Cursor */}

      <ScrollToTop />
      {/* End Scroll To Top */}

      <AllRoutes />
      {/* End All Routes */}

      {/* <ChatBotWidget /> */}

      <NotificationContainer />

      <CookiePopup />
    </>
  );
};

export default App;

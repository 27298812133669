import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

// import { loadSpaceAndEnvironment } from "../../../utils/contentful";
// import { getJobs, getNumberOfJobs } from "../../../utils/contentful/jobs";

import CareerListing from "../../../components/career/CareerListing";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import ContactJarallax from "../../../components/jarallax/ContactJarallax";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import SearchInput from "../../../components/SearchInput";

import { careers } from "../../../utils/data";

const Careers = () => {
  // const [careers, setCareers] = useState([]);
  // const [loadingCareers, setLoadingCareers] = useState(false);
  const [limitCount, setLimitCount] = useState(5);
  const [countCareers, setCountCareers] = useState(careers.length);
  const [filteredCareers, setFilteredCareers] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  // useEffect(() => {
  //   let mounted = true;

  //   const fetchArticles = async () => {
  //     if (mounted) {
  //       await loadSpaceAndEnvironment(); // load space and environment from contentful cms

  //       setLoadingCareers(true);
  //       const _careers = await getJobs({ limit: limitCount });
  //       console.log(_careers);
  //       const _count = await getNumberOfJobs();
  //       setLoadingCareers(false);
  //       setCareers(_careers);
  //       setCountCareers(_count);
  //       setFilteredCareers(_careers);
  //     }
  //   };
  //   fetchArticles();

  //   return () => {
  //     mounted = false;
  //   };
  // }, [limitCount]);

  useEffect(() => {
    const _careers = [...careers].slice(0, limitCount);

    if (searchKey !== "") {
      const _filteredCareers = _careers.filter(
        (item) =>
          item.title.toLowerCase().includes(searchKey.toLowerCase()) ||
          item.description.toLowerCase().includes(searchKey.toLowerCase())
      );
      setFilteredCareers(_filteredCareers);
    } else {
      setFilteredCareers(_careers);
    }
  }, [searchKey, limitCount]);

  const handleMoreClicked = () => {
    const moreCount = 5;
    const _limit = limitCount + moreCount;

    setLimitCount(_limit);
  };

  return (
    <div className="ptf-site-wrapper animsition">
      <Helmet>
        <title>Eklonet - Careers</title>
      </Helmet>

      <div className="ptf-site-wrapper__inner">
        <Header />

        <div className="ptf-main">
          <div className="ptf-page ptf-page--careers">
            <section>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-xl-8 col-lg-8">
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading d-inline-flex">
                        Careers
                      </h2>
                    </div>
                  </div>
                  <div className="col-xl-3 offset-xl-1 col-lg-4">
                    {/* <!--Animated Block--> */}
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="100"
                    >
                      <div className="ptf-widget ptf-widget-search">
                        <SearchInput
                          value={searchKey}
                          onChange={setSearchKey}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <section>
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "5rem", "--ptf-md": "5rem" }}
                ></div>
                <div className="container-xxl">
                  <div className="row">
                    <div className="col-lg-12">
                      {filteredCareers.length ? (
                        <CareerListing careers={filteredCareers} />
                      ) : (
                        <div className="container-xxl text-center">
                          <div
                            className="ptf-spacer"
                            style={{ "--ptf-xxl": "3.125rem" }}
                          ></div>
                          <h2 className="has-1-color">No results</h2>
                          <div
                            className="ptf-spacer"
                            style={{ "--ptf-xxl": "3.125rem" }}
                          ></div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-12">
                      <div className="text-center">
                        {limitCount < countCareers && (
                          <>
                            {/* <!--Spacer--> */}
                            <div
                              className="ptf-spacer"
                              style={{ "--ptf-xxl": "5rem" }}
                            ></div>

                            <div className="text-center">
                              <Link
                                to="#"
                                className="ptf-load-more"
                                onClick={handleMoreClicked}
                                disabled={loadingMore}
                              >
                                More
                              </Link>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
              ></div>
              <section
                className="jarallax jarallax-img"
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + "assets/img/galaxyart5.jpg"
                  })`,
                }}
              >
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": " 12.5rem", "--ptf-md": "6.25rem" }}
                ></div>
                <ContactJarallax />
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
                ></div>
              </section>
            </section>
          </div>
        </div>
      </div>

      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default Careers;

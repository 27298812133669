import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";

const menuContent = [
  {
    name: "Home",
    routerPath: "/",
  },
  {
    name: "Services",
    routerPath: "/services",
  },
  // {
  //   name: "Articles",
  //   routerPath: "/articles",
  // },
  {
    name: "Portfolio",
    routerPath: "/portfolio",
  },
  {
    name: "About Us",
    routerPath: "/about-us",
  },
  {
    name: "Careers",
    routerPath: "/careers",
  },
  {
    name: "Contact",
    routerPath: "/contact",
  },
  {
    name: "FAQs",
    routerPath: "/faq",
  },
];

const SliderMenu = () => {
  let slider;

  const settings = {
    autoplay: false,
    centerPadding: "40",
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
  };

  return (
    <div className="ptf-slider-menu-container">
      {/* Previous button */}
      <Link
        to="#"
        className="ptf-slider-menu-prev"
        onClick={() => slider.slickPrev()}
      >
        <i className="lnil lnil-chevron-left fz-24" />
      </Link>

      {/* Slider menu */}
      <Slider
        ref={(_slider) => (slider = _slider)}
        className="ptf-slider-menu"
        {...settings}
      >
        {menuContent.map((menuItem, i) => (
          <div className="ptf-slider-menu-item" key={i}>
            <Link
              to={menuItem.routerPath}
              className="ptf-slider-menu-item-link"
            >
              {menuItem.name}
            </Link>
          </div>
        ))}

        {/* Add more menu items as needed */}
      </Slider>

      {/* Next button */}
      <Link
        to="#"
        className="ptf-slider-menu-next"
        onClick={() => slider.slickNext()}
      >
        <i className="lnil lnil-chevron-right fz-24" />
      </Link>
    </div>
  );
};

export default SliderMenu;

import { createClient } from "contentful-management";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

const cmAccessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;
const gqlAccessToken = process.env.REACT_APP_GRAPHQL_ACCESS_TOKEN;
const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;

const gqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: `https://graphql.contentful.com/content/v1/spaces/${spaceId}`,
    headers: {
      Authorization: `Bearer ${gqlAccessToken}`,
    },
  }),
});

const cmClient = createClient({ accessToken: cmAccessToken });

let space, environment;

const loadSpaceAndEnvironment = async () => {
  try {
    const spaceResponse = await cmClient.getSpace(spaceId);
    space = spaceResponse;

    const environmentResponse = await space.getEnvironment("master");
    environment = environmentResponse;

    console.log("Contentful CMS successfully initialized");
  } catch (error) {
    console.error("Failed to initialize Contentful CMS", error);
  }
};

loadSpaceAndEnvironment();

const getTags = async (entryId) => {
  try {
    if (environment) {
      const tags = await environment.getTags();
      if (entryId) {
        const entry = await environment.getEntry(entryId);
        let entryTagIds = entry.metadata.tags.map((tag) => tag.sys.id);

        const entryTags = Promise.all(
          entryTagIds.map(async (tagId) => {
            const tag = await environment.getTag(tagId);

            return { id: tag.sys.id, name: tag.name };
          })
        );
        return entryTags;
      } else {
        return tags.map((tag) => ({ id: tag.sys.id, name: tag.name }));
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export { environment, getTags, gqlClient, loadSpaceAndEnvironment };

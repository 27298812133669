import React from "react";
import { Link } from "react-router-dom";

import { services } from "../../utils/data";

const ServiceOne = () => {
  return (
    <>
      {services.map((val, i) => (
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="0"
          key={i}
        >
          <Link to={`/portfolio?service=${val.slug}`}>
            {/* <!--Services Box--> */}
            <div
              className="ptf-service-box"
              style={{ borderWidth: i ? "2px" : 0 }}
            >
              {/* <Link
                to="/service-details"
                className="ptf-service-box__link"
              ></Link> */}
              <div className="ptf-service-box__icon">
                <i className={val.icon}></i>
              </div>
              <h5 className="ptf-service-box__title">
                {val.title} <br />
                {val.subTitle}
              </h5>
              <div className="ptf-service-box__content">
                <p>{val.descriptions}</p>
              </div>
              <div className="ptf-service-box__arrow">
                <i className="lnil lnil-chevron-right"></i>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default ServiceOne;

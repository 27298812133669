const careers = [
  {
    id: "career-1",
    location: "Baton Rouge, LA",
    title: "Sales Rep",
    description: `
      We are looking for a skilled sales representative to help us expand our client base and increase our revenue. In this role, you'll work closely with our team to identify new sales opportunities and bring in new business.

      Responsibilities:
      - Reach out to our existing leads and convert them into paying customers
      - Research and identify new sales opportunities and leads
      - Build and maintain relationships with potential and existing clients
      - Develop and implement effective sales strategies to meet and exceed our revenue goals
      - Negotiate and close deals with potential clients
      - Provide excellent customer service and support to our clients throughout the sales process
      
      Qualifications:
      - Excellent communication and interpersonal skills
      - Self-motivated and goal-oriented
      - Familiarity with web development industry and technology trends
      - Ability to work independently and as part of a team
      - Availability to attend meetings and events as necessary
      
      Compensation:
      - Commission-based pay structure
      - Generous commission rates on every sale
      
      If you're a skilled sales representative with a passion for web development and a desire to work with a dynamic and innovative team, we encourage you to apply for this position. Join Eklonet's sales team and help us achieve our goal of creating exceptional websites for businesses.`,
    skills: ["Sales", "Cold Calling", "Email Communication", "Outbound Sales"],
    date: "May 15, 2021",
  },
  {
    id: "career-2",
    location: "Baton Rouge, LA",
    title: "Lead Generation Specialist",
    description: `We are looking for someone who specializes in lead generation. There are many different ways to generate leads, so I am leaving that up to the contractor. The clients we are looking for are businesses that need a website built OR business and individuals who need their current website or applications updated.
      For each lead I will need:
      - The businesses/individual's name
      - The businesses contact number
      - Their contact email
      - Their current website's URL (if they have one)
      
      More about this job and other requirements will be discussed during an interview.`,
    skills: [
      "Marketing Strategy",
      "Internet Marketing",
      "Lead Generation",
      "Email Marketing",
    ],
    date: "May 15, 2021",
  },
  // {
  //   id: "work-3",
  //   location: "Baton Rouge, LA",
  //   title: "Electronics Engineer IV",
  //   description:
  //     "Serve in a leadership role of a small team of engineers (2-3 individuals) in developing electronic products/components for various clients. Lead team by applying technical expertise as well as guidance on best practices. Ensure that team is meting timelines met by project management. At direction of management, work with client to resolve complex/novel issues arising in the project. Identify opportunities to develop new business lines with client and relay them to project management. Travel and/or relocation to various unanticipated locations through the U.S. required. Utilize the following tools and technologies: IBM Rational DOORS, MATLAB/SIMULINK, DiffPlug, INCA.",
  //   skills: [
  //     "IBM Rational DOORS",
  //     "MATLAB/SIMULINK",
  //     "DiffPlug",
  //     "NICA",
  //     "Travel and/or relocation to various unanticipated locations throughout the U.S. required",
  //   ],
  //   date: "2021",
  //   imgPopup: "work-3",
  // },
  // {
  //   id: "work-4",
  //   location: "Baton Rouge, LA",
  //   title: "Mechanical Engineer II",
  //   description:
  //     "Develop products using Computer Aided Drafting (CAD) tools. Work with stakeholders to develop product requirements. Translate requirements into product designs. Design product testing protocols and work with stakeholders to evaluate designed products. Report results of product testing. Coordinate activities with other stakeholders to ensure that products are available when needed. Travel and/or relocation to various unanticipated locations throughout the U.S. required. Work with the following: Solidworks, Performance Testing of Functional Prototypes, Analyzing Quality Issues, Developing Manufacturing Processes.",
  //   skills: [
  //     "Solidworks",
  //     "Performance Testing of Functional Prototypes",
  //     "Analyzing Quality Issues",
  //     "Developing Manufacturing Processes",
  //     "Travel and/or relocation to various unanticipated locations throughout the U.S. required",
  //   ],
  //   date: "2021",
  //   imgPopup: "work-4",
  // },
  // {
  //   id: "work-5",
  //   location: "Baton Rouge, LA",
  //   title: "QA Analyst II",
  //   description:
  //     "Contribute to the development of software by performing QA/Testing. Contribute to testing plans to align with project goals/requirements/timelines. Perform testing procedures. Track issues using a tracking system. Work with developers to see that identified defects are corrected. Test solutions to confirm that they resolve the issues to be addressed. Travel and/or relocation to various unanticipated locations throughout the U.S. required. Utilize the following tools and technologies: Java, Oracle, Selenium Web Driver, Mercury Quality Center, JIRA.",
  //   skills: [
  //     "Java",
  //     "Selenium",
  //     "Oracle",
  //     "Web Driver",
  //     "Mercury Quality Center",
  //     "JIRA",
  //     "Travel and/or relocation to various unanticipated locations throughout the U.S. required",
  //   ],
  //   date: "2021",
  //   imgPopup: "work-5",
  // },
  // {
  //   id: "work-6",
  //   location: "Baton Rouge, LA",
  //   title: "Business Systems Developer III",
  //   description:
  //     "Analyze, develop and modify software applications to increase operating efficiency or adapt to new requirements. Consult with users to identify current operating procedures and clarify program objectives. Plan and implement systemic research and probing analysis of complex functional problems. Convert project specifications and statements of problems and procedures to detailed logical flow charts for coding into computer language. Contribute to the development and testing of new and enhanced products. Develop and write computer programs to store, locate, and retrieve specific documents, data, and information. Work with information technology to get the solutions into production. Travel and/or relocation to various unanticipated locations throughout the U.S. required. Utilize the following tools and technologies: JAVA/J2EE, Struts, Spring MVC, CSS, Java Script.Work with clients to assist in developing and improving IT/business systems. Work as part of a team to study existing client systems and meet with client/users in order to understand existing systems as well as the needs for new systems. Make recommendations for development plan. Work with others to translate client requirements/development plan into high quality IT products, consulting senior team members when issues arise. Engage in post-development error resolution and quality assurance. Travel and/or relocation to various unanticipated locations throughout the U.S. required. Utilize the following tools and technologies: Java, WebSphere MQ, Oracle SOA Suite, SOAPUI, Splunk, IBM ClearCase.",
  //   skills: [
  //     "Java",
  //     "WebSphere MQ",
  //     "Oracle SOA Suite",
  //     "SOAPUI",
  //     "Javascript",
  //     "IBM ClearCase",
  //     "Travel and/or relocation to various unanticipated locations throughout the U.S. required",
  //   ],
  //   date: "2021",
  //   imgPopup: "work-6",
  // },
];

const popularArticles = [
  {
    img: "/assets/img/articles/cloud-computing.jpg",
    category: "Computer Networking",
    title: "Cloud Computing",
    link: "/articles/7ddClGupIcdBXctKr873mD",
  },
  {
    img: "/assets/img/articles/chatgpt.jpg",
    category: "OpenAI",
    title: "ChatGPT",
    link: "/articles/3nIhsGnJs1RixJtdo3NCzz",
  },
  {
    img: "/assets/img/articles/blockchain.jpg",
    category: "Cryptography ",
    title: "Blockchain",
    link: "/articles/6l7dEMiQaJVGzaTzM1zxAO",
  },
];

const popularProjects = [
  {
    img: "/assets/img/portfolio/3eleven-2.jpg",
    title: "3ELEVEN",
    subTitle: "Luxury Apartment Rentals",
    link: "https://3elevennyc.com/",
  },
  {
    img: "/assets/img/portfolio/hyperai-2.jpg",
    title: "Hyper Fitness Coach",
    subTitle: "AI Powered Personal Trainer",
    link: "https://hyper-five.vercel.app/sign-in",
  },
  {
    img: "/assets/img/portfolio/sportdogfood-2.jpg",
    title: "Sport Dog Food",
    subTitle: "Premium Dog Food",
    link: "https://www.sportdogfood.com/",
  },
];

const projects = [
  {
    img: "/assets/img/portfolio/eventrap-2.jpg",
    title: "Event Rap",
    subTitle: "Musician Social Platform",
    link: "https://eventrap.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "Component", value: "component" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/hyperai-2.jpg",
    title: "Hyper Fitness Coach",
    subTitle: "AI Powered Personal Trainer",
    link: "https://hyper-five.vercel.app/sign-in",
    tags: [{ name: "Mobile Application", value: "app" }],
  },
  {
    img: "/assets/img/portfolio/heatherberlin-2.jpg",
    title: "Dr Heather Berlin",
    subTitle: "Personal Blog",
    link: "https://heatherberlin.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/nexient-2.jpg",
    title: "Nexient",
    subTitle: "Software Development Company",
    link: "https://www.nexient.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "UX/UI", value: "uxui" },
    ],
  },
  {
    img: "/assets/img/portfolio/allamreality-2.jpg",
    title: "Allam Property Group",
    subTitle: "Real Estate Listings",
    link: "https://www.allam.com.au/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
    ],
  },
  {
    img: "/assets/img/portfolio/justpark-2.jpg",
    title: "Just Park",
    subTitle: "Parking Spot Finder",
    link: "https://www.justpark.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "Mobile Application", value: "app" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
    ],
  },
  {
    img: "/assets/img/portfolio/seniorshield-2.jpg",
    title: "Senior Shield Homecare",
    subTitle: "Informational Website",
    link: "https://www.seniorshieldhomecare.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
    ],
  },
  {
    img: "/assets/img/portfolio/tatacliq-2.jpg",
    title: "Tata Cliq",
    subTitle: "Online Apparel Store",
    link: "https://www.tatacliq.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/3eleven-2.jpg",
    title: "3ELEVEN",
    subTitle: "Luxury Apartment Rentals",
    link: "https://3elevennyc.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "CMS", value: "cms" },
    ],
  },
  {
    img: "/assets/img/portfolio/thehendrix-2.jpg",
    title: "THE HENDRIX",
    subTitle: "Luxury Apartment Rentals",
    link: "https://www.thehendrixjc.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "CMS", value: "cms" },
    ],
  },
  {
    img: "/assets/img/portfolio/leleo-2.jpg",
    title: "LELÉO",
    subTitle: "Luxury Apartment Rentals",
    link: "https://www.leleo.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "CMS", value: "cms" },
    ],
  },
  {
    img: "/assets/img/portfolio/projectfit-2.jpg",
    title: "Project Fit",
    subTitle: "Personal Training",
    link: "https://www.projectfit.net/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "CMS", value: "cms" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/weare-2.jpg",
    title: "TM",
    subTitle: "Innovative Product Design Studio",
    link: "https://weare.tm/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
    ],
  },
  {
    img: "/assets/img/portfolio/molnlycke-2.jpg",
    title: "Mölnlycke",
    subTitle: "Medical Solutions Company",
    link: "https://www.molnlycke.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/sportdogfood-2.jpg",
    title: "Sport Dog Food",
    subTitle: "Premium Dog Food",
    link: "https://www.sportdogfood.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "CMS", value: "cms" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/hbs-2.jpg",
    title: "HBS",
    subTitle: "Business Management",
    link: "https://www.hbs.net",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/compareandchoose-2.jpg",
    title: "Compare and Choose",
    subTitle: "Travel Deals",
    link: "https://www.compareandchoose.com.au/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/inmaat-2.jpg",
    title: "Inmaat Albilad",
    subTitle: "Investors",
    link: "http://www.inmaat-albilad.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "UX/UI", value: "uxui" },
    ],
  },
  {
    img: "/assets/img/portfolio/koox-2.jpg",
    title: "Koox",
    subTitle: "Organic Juice Company",
    link: "https://koox.co.uk/home",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/kitamura-2.jpg",
    title: "Kitamura Makura",
    subTitle: "Premium Sewing Artist",
    link: "https://www.kitamura1923.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "UX/UI", value: "uxui" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/sslandscape-2.jpg",
    title: "SS Landscape Construction",
    subTitle: "Residential Property Construction",
    link: "http://sslandscapeconstruction.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/plasticdesign-2.jpg",
    title: "Plastic",
    subTitle: "Strategic Design Company",
    link: "https://plastic.design/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/songwhip-2.jpg",
    title: "Songwhip",
    subTitle: "Music Link Generator",
    link: "https://songwhip.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "Mobile Application", value: "app" },
    ],
  },
  {
    img: "/assets/img/portfolio/florenceacc-2.jpg",
    title: "Florence Accommodations",
    subTitle: "B&B Bookings",
    link: "https://www.florenceaccommodation.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/homely-2.jpg",
    title: "Homely",
    subTitle: "Real Estate Property Listings",
    link: "https://www.homely.com.au/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
    ],
  },
  {
    img: "/assets/img/portfolio/greenville.jpg",
    title: "Greenville SC Real Estate",
    subTitle: "Real Estate Agnecy",
    link: "https://www.greenvillescrealestate.net/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/wachp-2.jpg",
    title: "Waldon Adelman Castilla Hiestand & Prout",
    subTitle: "Litigation & Trial Attorneys",
    link: "https://www.wachp.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
    ],
  },
  {
    img: "/assets/img/portfolio/quinne-2.jpg",
    title: "Quinne Manuel Trial Lawyers",
    subTitle: "Corporate Law Firm",
    link: "https://www.quinnemanuel.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/daou-2.jpg",
    title: "DAOU",
    subTitle: "Wine Vineyard",
    link: "https://daouvineyards.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
    ],
  },
  {
    img: "/assets/img/portfolio/luxyhair-2.jpg",
    title: "Luxy",
    subTitle: "Hair Products",
    link: "https://www.luxyhair.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/beauteful-2.jpg",
    title: "BeautéfuLSkin",
    subTitle: "Skincare Products",
    link: "https://www.beautefulskin.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/goovi-2.jpg",
    title: "goovi",
    subTitle: "Cosmetic Products",
    link: "https://www.goovi.com/en",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/databroker-2.jpg",
    title: "databroker",
    subTitle: "Data Exchange Marketplace",
    link: "https://www.databroker.global/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/invalley-2.jpg",
    title: "Invalley",
    subTitle: "SEO Services",
    link: "https://invalley.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/transferito-2.jpg",
    title: "transferito",
    subTitle: "WordPress Migration Tool",
    link: "https://transferito.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/nationx-2.jpg",
    title: "nationX",
    subTitle: "Web3 Marketing",
    link: "https://www.nationx.io/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/maalgaadi-2.jpg",
    title: "MaalGaadi",
    subTitle: "Loading and Transportation Facilities",
    link: "https://www.maalgaadi.net/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "UX/UI", value: "uxui" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/troublemusic-2.jpg",
    title: "girls in trouble",
    subTitle: "Band Website",
    link: "https://www.girlsintroublemusic.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/investplus-2.jpg",
    title: "Investigations Plus",
    subTitle: "PI Agency",
    link: "https://investigationsplusltd.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/geeksupply-2.jpg",
    title: "GEEK SUPPLY CO",
    subTitle: "Tech Gadget Store",
    link: "https://geeksupply.co/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
  {
    img: "/assets/img/portfolio/greenand-2.jpg",
    title: "G + T",
    subTitle: "Travel Articles Blog",
    link: "https://www.greenandturquoise.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "CMS", value: "cms" },
    ],
  },
  {
    img: "/assets/img/portfolio/nosratilaw-2.jpg",
    title: "O|N",
    subTitle: "LA Law Office",
    link: "https://nosratilaw.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/cipia-2.jpg",
    title: "cipia",
    subTitle: "AI Software",
    link: "https://cipia.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
    ],
  },
  {
    img: "/assets/img/portfolio/aimbetter-2.jpg",
    title: "AimBetter",
    subTitle: "Database Management",
    link: "https://www.aimbetter.com/",
    tags: [
      { name: "Website", value: "website" },
      { name: "SEO", value: "seo" },
      { name: "E-commerce", value: "ecommerce" },
    ],
  },
];

const services = [
  {
    id: 1,
    slug: "cms",
    icon: "lnil lnil-write",
    title: "Web",
    subTitle: "Development",
    descriptions: `We excel in Full Stack Web Development, creating comprehensive websites and components that cover both front-end and back-end, providing clients with a seamless online presence.`,
  },
  {
    id: 2,
    slug: "uxui",
    icon: "lnil lnil-layout-alt-2",
    title: "Designing",
    subTitle: "",
    descriptions: `At our core, we are creators of digital experiences. Our web design expertise extends across the entire spectrum, resulting in captivating and user-friendly websites and components.`,
  },
  {
    id: 3,
    slug: "app",
    icon: "lnil lnil-rocket",
    title: "Web",
    subTitle: "APPLICATIONS",
    descriptions: `We specialize in building custom applications for clients to streamline their business processes, automate tasks, and provide unique solutions tailored to their specific needs.`,
  },
  {
    id: 4,
    slug: "website",
    icon: "lnil lnil-cogs",
    title: "WEBSITE",
    subTitle: "MAINTENANCE",
    descriptions: `We provide website maintenance services to clients to ensure their website stays up-to-date, secure, and performs at its best, freeing them from the hassle of managing technical aspects of their website.`,
  },
  {
    id: 5,
    slug: "seo",
    icon: "lnil lnil-graph-alt-4",
    title: "SEO",
    subTitle: "",
    descriptions: `We offer SEO services to clients to help increase their website's visibility and improve its ranking on search engines, attracting more traffic and potential customers.`,
  },
  {
    id: 6,
    slug: "ecommerce",
    icon: "lnil lnil-cart-alt",
    title: "Integrations",
    subTitle: "",
    descriptions: `We excel in website integrations, enhancing your site's capabilities by seamlessly connecting tools and data sources to streamline operations and expand functionality.`,
  },
  {
    id: 7,
    slug: "uxui",
    icon: "lnil lnil-layout-alt-2",
    title: "Website",
    subTitle: "Assistance",
    descriptions: `We offer comprehensive website assistance services, handling everything from domain setup and hosting to configuring website domain emails, so you can focus on what matters most – your business.`,
  },
];

export { careers, popularArticles, projects, services, popularProjects };

import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../../components/header/Header";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";

const Cookie = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--works-carousel">
      <Helmet>
        <title>Eklonet - Cookies Policy</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <Header />
        {/* End  HeaderHomeDefault */}

        <div className="ptf-main">
          <div className="ptf-page ptf-page--portfolio-carousel">
            {/*=============================================
                End Portfolio Section
              ============================================== */}
            <section>
              {/* spacer */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-12 col-md-9">
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading d-inline-flex">
                        Cookies Policy
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 text-md-end">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-md": "1.875rem" }}
                    ></div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "3.25rem", "--ptf-md": "5rem" }}
                ></div>
                <p>
                  <br />
                  <span style={{ fontSize: "28px", color: "#28abe3" }}>
                    Last Updated: February 12, 2023
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px" }}>
                    Eklonet is committed to protecting the privacy of our
                    website visitors and clients. This cookies policy explains
                    how we collect, use, and protect the personal information we
                    collect from you.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px" }}>
                    Our website uses targeting cookies to personalize your
                    experience and collect user preferences. We use this
                    information to enhance your browsing experience and to
                    tailor the content and advertisements that we show you.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px" }}>
                    We do not share this information with any third parties. The
                    cookies we use are only accessed by our website and are
                    stored on your device for a period of one month.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px" }}>
                    If you do not wish to accept cookies, you can click the
                    "Decline" button on the cookies pop-up that appears on our
                    website. Please note that disabling cookies may affect your
                    user experience on our website.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px" }}>
                    Our website does not have an age limit, but we do not
                    encourage children or teenagers to visit our website.
                  </span>
                  <br />
                  <br />
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    Contact us at{" "}
                    <a
                      href="mailto:contact@eklonet.com"
                      style={{ color: "#28abe3" }}
                    >
                      contact@eklonet.com
                    </a>{" "}
                    if you have any questions or concerns about this cookies
                    policy.
                  </span>
                  <br />
                </p>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "9rem" }}
              ></div>
            </section>
          </div>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default Cookie;

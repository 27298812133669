const convertDateToLocalDateString = (
  date,
  options = { year: "numeric", month: "short", day: "numeric" }
) => {
  const dateObj = new Date(date);
  const formattedDate = dateObj.toLocaleDateString("en-US", options);

  return formattedDate;
};

export { convertDateToLocalDateString };

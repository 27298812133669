import React from "react";

const SearchInput = ({ value, onChange }) => {
  return (
    <div className="ptf-search-form">
      <input
        type="text"
        placeholder="Search..."
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      {/* <button>
        <i className="lnil lnil-search-alt"></i>
      </button> */}
      <div className="ptf-search-form-icon">
        <i className="lnil lnil-search-alt"></i>
      </div>
    </div>
  );
};

export default SearchInput;

import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import Header from "../../../components/header/Header";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";

const ContactSuccess = () => {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (params.get("flag")) {
      const flag = /true/i.test(params.get("flag"));

      setSuccess(flag);
    } else navigate("/contact");
  }, [params, navigate]);

  return (
    <div>
      <Helmet>
        <title>Eklonet - Contact</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      <div className="ptf-main">
        <div className="ptf-page ptf-page--contact">
          <section>
            <div className="container-xxl text-center">
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <h2>
                {success
                  ? "Your submission was successful!"
                  : "Something went wrong. Please try again!"}
              </h2>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.125rem" }}
              ></div>
              <Link
                to="/contact"
                className="has-accent-1 ptf-link-with-arrow fz-36"
              >
                <i className="lnir lnir-arrow-left"></i>
                Go Back
              </Link>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
            </div>
          </section>
        </div>
      </div>

      {/*=============================================
        Start Footer
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ContactSuccess;

import React from "react";
import Social from "../../social/Social";
import { Link } from "react-router-dom";

const CopyRight = () => {
  return (
    <div className="row align-items-center">
      <div className="col-12 col-md">
        <p className="ptf-footer-copyright has-white-color">
          ©{new Date().getFullYear()} Eklonet | <Link to={"/CopyRight"}>All Rights
          Reserved</Link>
        </p>
      </div>
      {/* End .col */}

      <div className="col-12 col-md d-none d-xl-block"></div>
      {/* End .col */}

      <div className="col-12 col-lg">
        <div className="ptf-footer-socials has-white-color">
          {/* <!--Social Icon--> */}
          <Social />
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default CopyRight;

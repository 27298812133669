import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

import { convertDateToLocalDateString } from "../../utils/functions";

const CareerListing = ({ careers }) => {
  useEffect(() => {
    ReactTooltip.rebuild(); // Rebuild tooltips when the component mounts or updates
  }, []);

  return (
    <div className="ptf-careers">
      <div className="ptf-careers__container">
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <div className="ptf-careers__list">
            {/* <!--Portfolio Item--> */}
            {careers.map((item, i) => (
              <article
                className="ptf-careers__list-item ptf-work ptf-work--style-4"
                data-tip
                data-for={item.id}
                key={i}
              >
                <Link
                  className="ptf-careers__list-item-link"
                  to={`/careers/${item.id}`}
                ></Link>
                <div className="ptf-careers__list-item-subtitle">
                  {item.location}
                </div>
                <h4 className="ptf-careers__list-item-title">{item.title}</h4>
                <div className="ptf-careers__list-item-date">
                  {convertDateToLocalDateString(item.date)}
                </div>
              </article>
            ))}
            {careers.map((item, i) =>
              ReactDOM.createPortal(
                <ReactTooltip
                  id={item.id}
                  className="ptf-careers__tooltip"
                  place="right"
                  type="dark"
                  effect="float"
                  getContent={() => (
                    <div className="ptf-tooltip-content">
                      <div className="ptf-tooltip-header"></div>
                      <div className="ptf-tooltip-body">
                        <div className="fz-16 fw-bold has-black-color">
                          Description:
                        </div>
                        {/* <div
                          className="ptf-careers__list-item-description"
                          dangerouslySetInnerHTML={{
                            __html: documentToHtmlString(item?.description),
                          }}
                        /> */}
                        <div className="ptf-careers__list-item-description">
                          {item?.description}
                        </div>
                      </div>
                      <div className="ptf-tooltip-footer">
                        <div className="fz-16 fw-bold has-black-color">
                          Skills:
                        </div>
                        <ul className="ptf-careers__list-item-skills-list">
                          {item?.skills.map((skill) => (
                            <li key={skill}>{skill}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                />,
                document.body
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerListing;

import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../../components/header/Header";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";

const Privacy = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--works-carousel">
      <Helmet>
        <title>Eklonet - Privacy Policy</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <Header />
        {/* End  HeaderHomeDefault */}

        <div className="ptf-main">
          <div className="ptf-page ptf-page--portfolio-carousel">
            {/*=============================================
                End Portfolio Section
              ============================================== */}
            <section>
              {/* spacer */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-12 col-md-9">
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading d-inline-flex">
                        Privacy Policy
                      </h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 text-md-end">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-md": "1.875rem" }}
                    ></div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "3.25rem", "--ptf-md": "5rem" }}
                ></div>
                <p>
                  <br />
                  <span style={{ fontSize: "26px" }}>
                    Eklonet is committed to protecting the privacy of our
                    website visitors and clients. This privacy policy explains
                    how we collect, use, and protect the personal information we
                    collect from you.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px", color: "#28abe3" }}>
                    What personal information do we collect?
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    We only collect the clients name and email, their project’s
                    goals, ideal timeline, and the clients ideal budget for
                    their project, when they fill out the contact form on our
                    'contact us' page. Users are required to check the terms and
                    conditions box before submitting their request.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px", color: "#28abe3" }}>
                    How do we collect this information?
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    We collect personal information through our online contact
                    form.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px", color: "#28abe3" }}>
                    What do we use this information for?
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    We use this information to follow up on the client's contact
                    form inquiry.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px", color: "#28abe3" }}>
                    Do we share this information with third parties?
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    No, we do not share this information with any third parties.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px", color: "#28abe3" }}>
                    Do we use cookies or other tracking technologies?
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    Yes, we use cookies and browser local storage. We use
                    cookies to improve your experience on our website, to
                    understand how visitors use our website, and to personalize
                    content and advertising. When users first visit our website,
                    a small window pops-up asking if they accept our cookies
                    policy.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px", color: "#28abe3" }}>
                    How can users opt-out of these tracking technologies?
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    Users can opt-out of cookies and other tracking technologies
                    by adjusting their browser settings. However, please note
                    that disabling cookies may affect the functionality of our
                    website.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px", color: "#28abe3" }}>
                    How do we ensure the security of the personal information we
                    collect?
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    We take the security of your personal information seriously
                    and use HTTPS for site security.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "26px", color: "#28abe3" }}>
                    Changes to this Privacy Policy
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    We reserve the right to update or change this privacy policy
                    at any time. Any changes will be posted on this page and
                    will be effective immediately upon posting.
                    <br />
                    <br />
                  </span>
                  <span style={{ fontSize: "20px" }}>
                    Contact us at{" "}
                    <a
                      href="mailto:contact@eklonet.com"
                      style={{ color: "#28abe3" }}
                    >
                      contact@eklonet.com
                    </a>{" "}
                    if you have any questions or concerns about this privacy
                    policy.
                  </span>
                </p>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "9rem" }}
              ></div>
            </section>
          </div>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default Privacy;

import React from "react";
import { Link } from "react-router-dom";

import FooterList from "../list/FooterList";

const Footer = () => {
  return (
    <div className="row">
      <div className="col-12 col-lg">
        {/* <!--Animated Block--> */}
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <Link to="/">
            <img
              src="/assets/img/root/eklonet-logo-dark.png"
              alt="logo"
              loading="lazy"
            />
          </Link>
        </div>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "2.5rem" }}></div>
        <div className="ptf-animated-block" data-aos="fade" data-aos-delay="0">
          <div
            className="ptf-footer-heading has-white-color"
            style={{ maxWidth: "20rem" }}
          >
            Boost Your Business To the <b>Next</b> Level and{" "}
            <Link className="has-accent-1 fw-bold" to="/careers">
              Join
            </Link>{" "}
            the Team!
          </div>
        </div>
      </div>
      {/* End .col */}

      <div className="col-12 col-lg">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="100"
        >
          <div className="ptf-widget ptf-widget-links has-white-color">
            <FooterList />
          </div>
        </div>
      </div>
      {/* End .col */}
      <div className="col-12 col-lg">
        {/* <!--Animated Block--> */}
        <div
          className="ptf-animated-block"
          data-aos="fade"
          data-aos-delay="200"
        >
          <div className="ptf-widget ptf-widget-text">
            <Link
              to="/about-us"
              className="has-white-color ptf-link-with-arrow fz-30"
            >
              More Info
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 17 17"
                style={{ height: "1em" }}
              >
                <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
              </svg>
            </Link>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "0.625rem" }}
            ></div>
            <p className="fz-18 has-3-color">
              <i className="lnir lnir-world"></i> United States
            </p>
          </div>
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default Footer;

import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import BrandFive from "../../components/brand/BrandFive";
import ContactJarallax from "../../components/jarallax/ContactJarallax";
import CopyRight from "../../components/footer/copyright/CopyRight";
import Header from "../../components/header/Header";
import Hero from "../../components/hero/Hero";
import Footer from "../../components/footer/Footer";
import PopularArticles from "../inner-pages/articles/PopularArticles";
import PopularProjects from "../inner-pages/portfolio/PopularProjects";
import ServiceListFour from "../../components/list/ServiceListFour";
import TestimonialFour from "../../components/testimonial/TestimonialFour";

const Home = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--home-dark ">
      <Helmet>
        <title>Eklonet - Home</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header type="dark" />
      {/* End  Header */}

      <div className="ptf-site-wrapper__inner">
        <div className="ptf-main">
          <div className="ptf-page ptf-page--home-dark"></div>
          {/*=============================================
          Start Hero Section 
          ============================================== */}
          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "4.375rem", "--ptf-md": "2.1875rem" }}
            ></div>

            <div className="container-xxl">
              <Hero />
            </div>

            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "5rem", "--ptf-md": "5rem" }}
            ></div>
          </section>

          <section>
            <div className="container-xxl">
              {/* <!--Divider--> */}
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--ptf-color-white)",
                }}
              ></div>
            </div>
          </section>

          <section className="ptf-custom--1674">
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row" style={{ "--bs-gutter-y": "3.75rem" }}>
                <div className="col-3 col-sm-4 col-lg-3">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="fz-80 has-white-color lh-1">1</div>
                  </div>
                </div>
                <div className="col-9 col-sm-8 col-lg-2">
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "1.25rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="100"
                  >
                    <h2
                      className="
                          fz-16
                          lh-1p5
                          text-uppercase
                          has-3-color
                          fw-normal
                        "
                    >
                      Our <br />
                      Services
                    </h2>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <ServiceListFour />
                  </div>
                </div>
              </div>
            </div>
            {/* End container-xxl */}

            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>

            <div className="container-xxl">
              {/* <!--Divider--> */}
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--ptf-color-white)",
                }}
              ></div>
            </div>

            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row" style={{ "--bs-gutter-y": "3.75rem" }}>
                <div className="col-3 col-sm-4 col-lg-3">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="fz-80 has-white-color lh-1">2</div>
                  </div>
                </div>
                <div className="col-9 col-sm-8 col-lg-6">
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "0.75rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="200"
                  >
                    <h2 className="fz-48 text-center has-3-color">
                      Popular Projects
                    </h2>
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      style={{ height: "1em" }}
                      viewBox="0 0 17 17"
                    >
                      <path d="M16 .997V10h-1V2.703L4.683 13l-.707-.708L14.291 1.997H6.975v-1H16z" />
                    </svg> */}
                  </div>
                </div>
                <div className="col-lg-3">
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.125rem", "--ptf-sm": 0 }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="fz-20 has-white-color text-end">
                      <Link to="/portfolio" className="ptf-link-with-arrow">
                        View more <i className="lnil lnil-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* End .row */}

              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.125rem" }}
              ></div>

              <div
                className="ptf-animated-block"
                data-aos="fade"
                data-aos-delay="200"
              >
                <PopularProjects />
              </div>
            </div>
            {/* End .container-xxl */}

            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>
          {/* End .ptf-custom--1674 */}

          <section>
            <div className="container-xxl">
              {/* <!--Divider--> */}
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--ptf-color-white)",
                }}
              ></div>
            </div>
          </section>

          <section>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row" style={{ "--bs-gutter-y": "3.75rem" }}>
                <div className="col-3 col-sm-4 col-lg-3">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="fz-80 has-white-color lh-1">3</div>
                  </div>
                </div>
                <div className="col-9 col-sm-8 col-lg-2">
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "1.25rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="100"
                  >
                    <h2
                      className="
                          fz-16
                          lh-1p5
                          text-uppercase
                          has-3-color
                          fw-normal
                        "
                    >
                      Trusted <br />
                      Partners
                    </h2>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <BrandFive />
                  </div>
                </div>
              </div>
            </div>
            {/* End .container-xxl */}

            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section>

          <section>
            <div className="container-xxl">
              {/* <!--Divider--> */}
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--ptf-color-white)",
                }}
              ></div>
            </div>
          </section>

          {/* section: popular articles */}

          {/* <section>
            <div className="container-xxl">
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--ptf-color-white)",
                }}
              ></div>
            </div>

            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>

            <div className="container-xxl">
              <div className="row" style={{ "--bs-gutter-y": "3.75rem" }}>
                <div className="col-3 col-sm-4 col-lg-3">
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="fz-80 has-white-color lh-1">4</div>
                  </div>
                </div>
                <div className="col-9 col-sm-8 col-lg-6">
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "0.75rem" }}
                  ></div>
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="200"
                  >
                    <h2 className="fz-48 text-center has-3-color">
                      Popular Articles
                    </h2>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "3.125rem", "--ptf-sm": 0 }}
                  ></div>
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="fz-20 has-white-color text-end">
                      <Link to="/articles" className="ptf-link-with-arrow">
                        View more <i className="lnil lnil-arrow-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "3.125rem" }}
              ></div>

              <div className="row">
                <div className="col-lg-12 has-dark-color">
                  <PopularArticles />
                </div>
              </div>
            </div>
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
          </section> */}

          <section>
            <div className="container-xxl">
              {/* <!--Divider--> */}
              <div
                className="ptf-divider"
                style={{
                  "--ptf-height": "1px",
                  "--ptf-color": "var(--ptf-color-white)",
                }}
              ></div>
            </div>
          </section>

          <section className="ptf-custom--1772">
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
            ></div>
            <div className="container-xxl">
              <div className="row" style={{ "--bs-gutter-y": "3.75rem" }}>
                <div className="col-3 col-sm-4 col-lg-3">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="0"
                  >
                    <div className="fz-80 has-white-color lh-1">4</div>
                  </div>
                </div>
                <div className="col-9 col-sm-8 col-lg-2">
                  {/* <!--Spacer--> */}
                  <div
                    className="ptf-spacer"
                    style={{ "--ptf-xxl": "1.25rem" }}
                  ></div>
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="100"
                  >
                    <h2
                      className="
                          fz-16
                          lh-1p5
                          text-uppercase
                          has-3-color
                          fw-normal
                        "
                    >
                      What <br />
                      People Are Saying
                    </h2>
                  </div>
                </div>
                <div className="col-lg-7">
                  {/* <!--Animated Block--> */}
                  <div
                    className="ptf-animated-block"
                    data-aos="fade"
                    data-aos-delay="200"
                  >
                    {/* <!--Testimonial--> */}
                    <TestimonialFour />
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* End .ptf-custom--1772 */}
          {/* <!--Spacer--> */}
          <div
            className="ptf-spacer"
            style={{ "--ptf-xxl": "6.25rem", "--ptf-md": "3.125rem" }}
          ></div>

          {/*=============================================
            Start Contact Section 
            ============================================== */}
          <section
            className="jarallax jarallax-img"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "assets/img/galaxyart5.jpg"
              })`,
            }}
          >
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": " 12.5rem", "--ptf-md": "6.25rem" }}
            ></div>
            <ContactJarallax />
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
          </section>

          {/* End .ptf-page */}
        </div>
        {/* End .ptf-main */}
      </div>
      {/* End .ptf-site-wrapper__inner */}

      {/*=============================================
        Start Footer Section 
        ============================================== */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          {/* End .ptf-footer__top */}

          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
          {/* End .ptf-footer__bottom */}
        </div>
      </footer>
    </div>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

// import { getJob } from "../../../utils/contentful/jobs";

import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import SocialShare from "../../../components/social/SocialShare";
import Tag from "../../../components/tag/Tag";
import { convertDateToLocalDateString } from "../../../utils/functions";

import { careers } from "../../../utils/data";

const CareerDetail = () => {
  const params = useParams();
  const jobId = params?.id;
  const [job, setJob] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let mounted = true;

    (async () => {
      if (jobId) {
        if (mounted) {
          setLoading(true);
          // const _job = await getJob(jobId);
          const _job = careers.find((career) => career.id === jobId);
          setLoading(false);
          setJob(_job);
        }
      }
    })();

    return () => {
      mounted = false;
    };
  }, [jobId]);

  return (
    <div className="ptf-site-wrapper animsition ptf-is--blog-grid">
      <Helmet>
        <title>Eklonet - Job Detail</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <Header />
        {/* End  HeaderHomeDefault */}

        <div className="ptf-main">
          <article className="ptf-single-post">
            {/* <!--Post Header--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
            <header className="ptf-single-post__header ptf-single-post__header--style-1">
              <div className="container-xxl">
                <h1 className="ptf-single-post__title">
                  {loading ? <Skeleton count={1} width="50%" /> : job?.title}
                </h1>
              </div>
            </header>

            {/* <!--Post Wrapper--> */}
            <div className="ptf-single-post__wrapper">
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "5rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row">
                  <div className="col-xl-8 offset-xl-2">
                    <div className="ptf-single-post__info">
                      <div className="ptf-single-post__meta">
                        <Link className="author" to="#">
                          <i className="lnil lnil-location"></i>
                          {loading ? (
                            <Skeleton width={60} />
                          ) : (
                            <>
                              Location: <span>{job?.location}</span>
                            </>
                          )}
                        </Link>
                      </div>
                      <div className="ptf-single-post__meta">
                        <span className="cat"></span>
                        {loading ? (
                          <div>
                            <Skeleton count={1} width={100} />
                          </div>
                        ) : (
                          <span className="date">
                            {job?.date
                              ? `Published on ${convertDateToLocalDateString(
                                  job?.date
                                )}`
                              : ""}
                          </span>
                        )}
                      </div>
                    </div>
                    {/* <!--Post Content--> */}
                    {loading ? (
                      <Skeleton count={6} />
                    ) : (
                      <div
                        className="ptf-animated-block"
                        data-aos="fade"
                        data-aos-delay="100"
                      >
                        <div className="fz-18 fw-bold has-accent-1">
                          Description:
                        </div>
                        <br />
                        {/* <div
                          className="ptf-single-post__content"
                          dangerouslySetInnerHTML={{
                            __html: documentToHtmlString(job?.description),
                          }}
                        /> */}
                        <div className="ptf-single-post__content">
                          {job?.description}
                        </div>
                      </div>
                    )}
                    {job?.skills.length > 0 && (
                      <>
                        <div className="ptf-single-post__tags">
                          {/* <!--Animated Block--> */}
                          <div
                            className="ptf-animated-block"
                            data-aos="fade"
                            data-aos-delay="100"
                          >
                            <div className="fz-18 fw-bold has-accent-1">
                              Required skills:{" "}
                            </div>
                            <div className="text-center">
                              {job?.skills.map((skill) => (
                                <Tag key={skill}>{skill}</Tag>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-xxl": "5rem" }}
                    ></div>

                    <div className="text-center">
                      {job?.link ? (
                        <a
                          href={job?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <button className="ptf-btn ptf-btn--secondary ptf-btn--block text-uppercase">
                            Apply Now
                          </button>
                        </a>
                      ) : (
                        <Link to={`/careers/${job?.id}/apply`}>
                          <button className="ptf-btn ptf-btn--secondary ptf-btn--block text-uppercase">
                            Apply Now
                          </button>
                        </Link>
                      )}
                    </div>

                    {/* <!--Post Footer--> */}
                    <footer className="ptf-single-post__footer">
                      {/* <!--Post Tags--> */}
                      <div className="ptf-post-tags">
                        {/* <a href="#">WordPress</a>
                        <a href="#">Theme</a>
                        <a href="#">Creative</a> */}
                      </div>

                      {/* <!--Post Socials-->  */}
                      {loading ? (
                        <Skeleton width={300} style={{ marginRight: 0 }} />
                      ) : (
                        <div
                          className="ptf-animated-block"
                          data-aos="fade"
                          data-aos-delay="100"
                        >
                          <div className="ptf-post-socials">
                            <span>Share on</span>
                            <SocialShare
                              url={`/careers/${job?.id}`}
                              title={job?.title}
                              description={job?.description}
                            />
                          </div>
                        </div>
                      )}
                    </footer>
                  </div>
                </div>
              </div>
            </div>
            {/* <!--Spacer--> */}
            <div
              className="ptf-spacer"
              style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
            ></div>
          </article>
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default CareerDetail;

import React from "react";
import { Link } from "react-router-dom";

const menuContent = [
  {
    name: "Home",
    activeClass: "",
    menuClass: "",
    routerPath: "/",
  },
  {
    name: "Services",
    activeClass: "",
    menuClass: "",
    routerPath: "/services",
  },
  // {
  //   name: "Articles",
  //   activeClass: "",
  //   menuClass: "",
  //   routerPath: "/articles",
  // },
  {
    name: "Portfolio",
    activeClass: "",
    menuClass: "",
    routerPath: "/portfolio",
  },
  {
    name: "Extras",
    activeClass: "sf-with-ul",
    menuClass: "",
    dropDownItems: [
      {
        name: "About Us",
        routerPath: "/about-us",
      },
      {
        name: "Careers",
        routerPath: "/careers",
      },
      {
        name: "Contact",
        routerPath: "/contact",
      },
      {
        name: "FAQs",
        routerPath: "/faq",
      },
    ],
  },
];

const DropdownMenu = () => {
  return (
    <ul className="sf-menu">
      {menuContent.map((item, i) => (
        <li
          className={`${
            item.hasOwnProperty("dropDownItems") ? "menu-item-has-children" : ""
          } ${item.menuClass}`}
          key={i}
        >
          <Link
            to={item["routerPath"] ? item["routerPath"] : "#"}
            className={item.activeClass}
          >
            <span>{item.name}</span>
          </Link>
          {item.hasOwnProperty("dropDownItems") && (
            <ul className="sub-menu mega">
              {item.dropDownItems.map((val, i) => (
                <li key={i}>
                  <Link to={val.routerPath}>
                    {" "}
                    <span>{val.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default DropdownMenu;

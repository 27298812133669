import React from "react";

const approachContent = [
  {
    subTitle: "ONE",
    title: "Intro",
    descriptions: `The development team will meet with the client to gain a clear understanding of their needs, goals, and expectations for the website. This will involve gathering information about the client's target audience, industry, and brand identity. The team will use this information to create a blueprint for the website and establish a clear scope of work.`,
    delayAnimation: "0",
  },

  {
    subTitle: "TWO",
    title: "Strategy",
    descriptions: `Based on the information gathered in step one, the development team will start to brainstorm and come up with a concept for the website. This includes creating a sitemap, defining the user journey, and establishing a design direction that aligns with the client's brand identity.`,
    delayAnimation: "100",
  },
  {
    subTitle: "THREE",
    title: "Design",
    descriptions: `Once the concept has been established, the team will create wireframes and mockups of the website's pages and features. These will be adjusted based on the client's feedback to ensure that the design meets their expectations and aligns with their brand guidelines.`,
    delayAnimation: "200",
  },
  {
    subTitle: "FOUR",
    title: "Development",
    descriptions: `With the design and mockups finalized, the development team can begin coding the website. It's important to keep the client involved in the process and show them the progress made along the way. This helps to ensure that the project stays on track and any issues can be addressed early on.`,
    delayAnimation: "300",
  },
  {
    subTitle: "FIVE",
    title: "Testing",
    descriptions: `Once the development is complete, the website will be thoroughly tested to ensure that all features and functionalities are working as intended. Any bugs or errors will be addressed and then the website will be ready for launch.`,
    delayAnimation: "400",
  },
  {
    subTitle: "SIX",
    title: "Hand Over",
    descriptions: `Once the website has been finalized, it's time to hand it over to the client. The development team will provide any necessary training to help the client manage and update the website, and will also offer ongoing support if requested.`,
    delayAnimation: "500",
  },
];

const Approach = () => {
  return (
    <div
      className="row"
      style={{
        "--bs-gutter-x": "3.75rem",
        "--bs-gutter-y": "7.5rem",
      }}
    >
      {approachContent.map((val, i) => (
        <div className="col-xl-4 col-lg-6" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={val.delayAnimation}
          >
            {/* <!--Approach Box--> */}
            <div className="ptf-approach-box">
              <div className="ptf-approach-box__subtitle">{val.subTitle}</div>
              <h3 className="ptf-approach-box__title h1">{val.title}</h3>
              <div className="ptf-approach-box__content">
                <p>{val.descriptions}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Approach;

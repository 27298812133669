import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "How much does a website cost to build?",
    desc: `The cost of building a website depends on various factors such
     as the complexity of the design, functionality, and features required.`,
    expand: "a",
  },
  {
    title: "How does the payment system work?",
    desc: `A security deposit is required to start the project. Progress payments are
    also required throughout various stages of the project.`,
    expand: "b",
  },
  {
    title: "If I am not satisfied with the product, can I get a refund?",
    desc: `We do not allow refunds because throughout the project's duration
    we make the proper adjustments to insure that the client is satisfied.`,
    expand: "c",
  },
  {
    title: "Do you provide an NDA Agreement?",
    desc: `Only if an NDA is requested by the client.`,
    expand: "d",
  },
];

const Faq = () => {
  return (
    <>
      <dl className="ptf-accordion">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem key={i} uuid={item.expand}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <dt>{item.title}</dt>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel>
                <dd>{item.desc}</dd>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </dl>
    </>
  );
};

export default Faq;

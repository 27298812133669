import React from "react";
import { Helmet } from "react-helmet";

import Header from "../../../components/header/Header";
import CopyRight from "../../../components/footer/copyright/CopyRight";
import Footer from "../../../components/footer/Footer";

const Terms = () => {
  return (
    <div className="ptf-site-wrapper animsition ptf-is--works-carousel">
      <Helmet>
        <title>Eklonet - Terms and Conditions</title>
      </Helmet>
      {/* End Page SEO Content */}
      <div className="ptf-site-wrapper__inner">
        <Header />
        {/* End  HeaderHomeDefault */}

        <div className="ptf-main">
          <div className="ptf-page ptf-page--portfolio-carousel">
            {/*=============================================
                End Portfolio Section
              ============================================== */}
            <section>
              {/* spacer */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "5rem" }}
              ></div>
              <div className="container-xxl">
                <div className="row align-items-center">
                  <div className="col-12 col-md-9">
                    <div
                      className="ptf-animated-block"
                      data-aos="fade"
                      data-aos-delay="0"
                    >
                      <h2 className="h1 large-heading d-inline-flex">Terms and Conditions</h2>
                    </div>
                  </div>
                  <div className="col-12 col-md-3 text-md-end">
                    {/* <!--Spacer--> */}
                    <div
                      className="ptf-spacer"
                      style={{ "--ptf-md": "1.875rem" }}
                    ></div>
                  </div>
                </div>
                {/* <!--Spacer--> */}
                <div
                  className="ptf-spacer"
                  style={{ "--ptf-xxl": "3.25rem", "--ptf-md": "5rem" }}
                ></div>
                <p>
                  <br />
                  <span style={{ fontSize: "26px" }}>
                    Welcome to Eklonet website. By accessing or using our website, you 
                    agree to be bound by the following terms and conditions. If you do not agree 
                    to these terms and conditions, you may not access or use our website.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    1. Description of Services: Eklonet provides web development services, 
                    including responsive website design and coding, CMS websites, e-commerce websites, 
                    website SEO, website maintenance, and web applications. These services 
                    are subject to the terms and conditions outlined in this agreement.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    2. Payment and Refunds: Clients must pay a 10% deposit before 
                    Eklonet begins work on their project. Eklonet does 
                    not provide refunds.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    3. Privacy Policy: Eklonet is committed to protecting the privacy 
                    of our users. We collect user data, such as name, email, and project 
                    information, solely for the purpose of providing our services. 
                    We will not share this data with any third parties without user 
                    consent, except as required by law.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    4. Intellectual Property: All content on Eklonet website, including text, 
                    images, logos, and designs, is protected by copyright and other 
                    intellectual property laws. Users may not use or reproduce this content 
                    without the express written permission of Eklonet.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    5. Limitation of Liability: Eklonet is not liable for any damages, 
                    including but not limited to direct, indirect, incidental, or consequential 
                    damages, arising from the use of our website or services. We make no 
                    warranties or representations regarding the accuracy or reliability of 
                    our website or services.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    6. Governing Law: These terms and conditions shall be governed by and 
                    construed in accordance with the laws of the state of Louisiana. 
                    Any disputes arising under or in connection with these terms and conditions 
                    shall be subject to the exclusive jurisdiction of the courts of United States of America.
                  </span>
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    7. Termination: Either party may terminate this agreement at any 
                    time for any reason upon written notice to the other party. 
                    Upon termination, Eklonet shall retain all rights to the project materials, 
                    and the client shall have no further rights to use or reproduce 
                    these materials without the express written consent of Eklonet.
                  </span>
                  <br />
                  <br />
                  <span style={{ fontSize: "20px" }}>
                    By accessing or using our website, you agree to be bound by these 
                    terms and conditions. Please contact us at&nbsp; 
                    <a
                      href="mailto:contact@eklonet.com"
                      style={{ color: "#28abe3" }}
                    >contact@eklonet.com </a>if there are any questions or concerns.
                  </span>
                </p>
              </div>
              {/* <!--Spacer--> */}
              <div
                className="ptf-spacer"
                style={{ "--ptf-xxl": "10rem", "--ptf-md": "9rem" }}
              ></div>
            </section>
          </div>
          {/* End .ptf-page */}
        </div>
      </div>
      {/* End .main */}

      {/* <!--Footer--> */}
      <footer className="ptf-footer ptf-footer--style-3">
        <div className="container-xxl">
          <div className="ptf-footer__top">
            <Footer />
          </div>
          <div className="ptf-footer__bottom">
            <CopyRight />
          </div>
        </div>
      </footer>
    </div>
    // End .ptf-is--blog-grid
  );
};

export default Terms;
